import React from 'react';
import cv from '../assets/pdfs/cv.pdf';
import SimpleLayout from '../components/layouts/simple-layout';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { H1 } from '../components/design';
import SEO from '../components/seo';
import HeroLayout from '../components/layouts/hero-layout';
import { HeroElement } from '../components/layouts';

const hero = <HeroElement title="Resume" />;

const ResumePage: React.FC = () => (
  <HeroLayout hero={hero}>
    <SEO title="Resume" description="Resume (CV) of Piotr Staniów" />
    <header className="lg:text-right">
      <a
        href={cv}
        target="_blank"
        rel="noopener noreferrer"
        role="button"
        className={classNames(
          'hover:bg-main-lighter',
          'text-main',
          'font-semibold',
          'font-serif',
          'hover:text-white',
          'px-4',
          'py-2',
          'inline-block',
          'border',
          'border-main',
          'hover:border-transparent',
          'rounded',
        )}
      >
        <svg
          className="fill-current w-4 h-4 mr-2 inline align-baseline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
        </svg>
        Download PDF
      </a>
    </header>
    <StaticImage
      src="../assets/pdfs/resume.png"
      alt="Image of the resume - Download PDF button is available"
      layout="fullWidth"
      width={960}
      placeholder="blurred"
      formats={['auto', 'webp', 'avif']}
      aspectRatio={2481 / 3509}
      className="w-full mx-auto overflow-auto border border-gray-300 my-6"
    />
  </HeroLayout>
);

export default ResumePage;
